import { uid } from 'uid';

export const signIn = (credentials) => {

    return (dispatch, getState, {getFirebase}) => {
      const firebase = getFirebase();
      
      firebase.auth().signInWithEmailAndPassword(
        credentials.email,
        credentials.password
      ).then(() => {
        dispatch({ type: 'LOGIN_SUCCESS' });
      }).catch((err) => {
        dispatch({ type: 'LOGIN_ERROR', err });
      });
  
    }
  }
  
  export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
      const firebase = getFirebase();
  
      firebase.auth().signOut().then(() => {
        dispatch({ type: 'SIGNOUT_SUCCESS' })
      });
    }
  }
  
export const signUp = (newUser) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
      const firebase = getFirebase();
      const firestore = getFirestore();
  
      firebase.auth().createUserWithEmailAndPassword(
        newUser.email, 
        newUser.password
      ).then(resp => {
        return firestore.collection('users').doc(resp.user.uid).set({
          name: newUser.name,
          SRN: resp.user.uid,
          userType: newUser.type, 
          phone: newUser.phone,
          email: newUser.email,
          gender: newUser.gender,
        });
      }).then(() => {
        dispatch({ type: 'SIGNUP_SUCCESS' });
      }).catch((err) => {
        dispatch({ type: 'SIGNUP_ERROR', err});
      });
    }
  }


  export const addStudent = (studentInfo) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
    
        firebase.auth().createUserWithEmailAndPassword(
          studentInfo.email, 
          studentInfo.password
        ).then(resp => {
          return firestore.collection('users').doc(resp.user.uid).set({
            name: studentInfo.name,
            SRN: resp.user.uid,
            userType: "Student", 
            phone: studentInfo.phone,
            email: studentInfo.email,
            gender: "male",
          });
        }).then(() => {
          
        }).catch((err) => {
          
        });
      }
    }