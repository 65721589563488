import React from 'react'

const Footer = () => {
    return(
        <footer className="footer">
            <p className="mt-2"> Copyright Tiếng Hàn Thầy Tư &copy; 2022</p>
        </footer>
    )
}

export default Footer;